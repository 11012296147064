import Web3 from "web3";
import Web3Modal from "web3modal";
import { CHAIN_ID, CHAIN_NAME, CURRENCY, DEBUG, DECIMALS, EXPLORER_URL, RPC_URL, SYMBOL } from "../../config";

class Web3Service {
  constructor() {
    this.callbacks = [];
    this.init().then();
  }

  ////////////////////////////////////////
  // init functions

  init = async () => {
    this.initProvider();
    this.initWeb3();
    this.initWeb3Modal();
    this.listen();
    this.address = await this.getAddress();
    this.chainId = await this.getChainId();
    this.callback();
  };

  initProvider = () => {
    if (!this.provider) {
      this.provider = Web3.givenProvider || RPC_URL;
    }
  };

  initWeb3 = () => {
    if (this.provider) {
      this.web3 = new Web3(this.provider);
      this.web3.eth.extend({
        methods: [
          {
            name: "chainId",
            call: "eth_chainId",
          },
        ],
      });
    }
  };

  initWeb3Modal = () => {
    this.web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: {},
      disableInjectedProvider: false,
    });
  };

  ////////////////////////////////////////
  // callbacks

  listen = () => {
    if (this.provider?.on) {
      this.provider.on("connect", (data) => {
        DEBUG && console.debug("Web3Service.listen.connect", data);
        this.callback();
      });

      this.provider.on("accountsChanged", async (accounts) => {
        this.address = accounts.length > 0 ? this.web3.utils.toChecksumAddress(accounts[0]) : null;
        DEBUG && console.debug("Web3Service.listen.accountsChanged", accounts);
        this.callback();
      });

      this.provider.on("chainChanged", async (chainId) => {
        this.chainId = chainId;
        DEBUG && console.debug("Web3Service.listen.chainChanged", chainId);
        this.callback();
      });

      this.provider.on("message", (data) => {
        DEBUG && console.debug("Web3Service.listen.message", data);
        this.callback();
      });

      this.provider.on("disconnect", (data) => {
        DEBUG && console.debug("Web3Service.listen.disconnect", data);
        this.callback();
      });
    }
  };

  subscribe = (callback) => {
    callback && this.callbacks.push(callback);
  };

  callback = () => {
    this.callbacks.forEach((callback) => callback());
  };

  ////////////////////////////////////////
  // connect functions

  connect = async () => {
    try {
      this.provider = await this.web3Modal.connect();
    } catch (e) {
      DEBUG && console.error("Web3Service.connect.exception", e);
    }
    this.init().then();
  };

  disconnect = async () => {
    try {
      await this.web3?.currentProvider?.close?.();
      await this.web3Modal.clearCachedProvider();
      this.provider = null;
      this.init().then();
    } catch (e) {
      DEBUG && console.error("Web3Service.disconnect.exception", e);
    }
  };

  switchChain = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: CHAIN_ID }],
      });
      this.init().then();
      return true;
    } catch (e) {
      const errorCode = e.data?.originalError?.code;
      if ((errorCode && errorCode === 4902) || e.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: CHAIN_ID,
                chainName: CHAIN_NAME,
                rpcUrls: [RPC_URL],
                blockExplorerUrls: [EXPLORER_URL],
                nativeCurrency: {
                  name: CURRENCY,
                  symbol: SYMBOL,
                  decimals: DECIMALS,
                },
              },
            ],
          });
          this.init().then();
          return true;
        } catch (e) {
          DEBUG && console.error("Web3Service.switchChain.exception", e);
        }
      } else {
        DEBUG && console.error("Web3Service.switchChain.exception", e);
      }
    }
    return false;
  };

  ////////////////////////////////////////
  // get data

  getBlockNumber = async () => {
    if (this.web3) {
      return await this.web3.eth.getBlockNumber();
    }
    return null;
  };

  getBlock = async (blockNumber) => {
    if (this.web3) {
      return await this.web3.eth.getBlock(blockNumber);
    }
    return null;
  };

  getAddress = async () => {
    if (this.web3) {
      const accounts = await this.web3.eth.getAccounts();
      if (accounts.length > 0) {
        return this.web3.utils.toChecksumAddress(accounts[0]);
      }
    }
    return null;
  };

  getChainId = async () => {
    if (this.web3) {
      return await this.web3.eth.chainId();
    }
    return null;
  };

  ////////////////////////////////////////
  // helpers

  toBN = (value) => {
    return new this.web3.utils.BN(value);
  };

  fromWei = (value) => {
    return this.web3.utils.fromWei(value);
  };

  toWei = (value) => {
    try {
      return this.web3.utils.toWei(value);
    } catch (e) {}
    return null;
  };

  isAddress = (address) => {
    try {
      return this.web3.utils.isAddress(address);
    } catch (e) {}
    return null;
  };

  add = (value1Wei, value2Wei) => {
    try {
      const value1BN = this.toBN(value1Wei);
      const value2BN = this.toBN(value2Wei);
      return value1BN.add(value2BN);
    } catch (e) {}
    return false;
  };

  sub = (value1Wei, value2Wei) => {
    try {
      const value1BN = this.toBN(value1Wei);
      const value2BN = this.toBN(value2Wei);
      return value1BN.sub(value2BN);
    } catch (e) {}
    return false;
  };

  percentage = (numeratorWei, denominatorWei) => {
    try {
      const numeratorBN = this.toBN(numeratorWei);
      const denominatorBN = this.toBN(denominatorWei);
      const hundredBN = this.toBN(this.toWei("100"));
      return numeratorBN.mul(hundredBN).div(denominatorBN);
    } catch (e) {}
    return false;
  };

  gt = (value, minWei = 0) => {
    try {
      const valueBN = this.toBN(this.toWei(value));
      const minBN = this.toBN(minWei);
      return valueBN.gt(minBN);
    } catch (e) {}
    return false;
  };

  gte = (value, minWei) => {
    try {
      const valueBN = this.toBN(this.toWei(value));
      const minBN = this.toBN(minWei);
      return valueBN.gte(minBN);
    } catch (e) {}
    return false;
  };

  lte = (value, maxWei) => {
    try {
      const valueBN = this.toBN(this.toWei(value));
      const maxBN = this.toBN(maxWei);
      return valueBN.lte(maxBN);
    } catch (e) {}
    return false;
  };

  between = (value, minWei, maxWei) => {
    try {
      const valueBN = this.toBN(this.toWei(value));
      const minBN = this.toBN(minWei);
      const maxBN = this.toBN(maxWei);
      return !valueBN.isZero() && valueBN.gte(minBN) && valueBN.lte(maxBN);
    } catch (e) {}
    return false;
  };

  isZero = (valueWei) => {
    try {
      const valueBN = this.toBN(valueWei);
      return valueBN.isZero();
    } catch (e) {}
    return false;
  };
}

export const web3Service = new Web3Service();
