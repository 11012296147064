import cn from "classnames";
import "./Tooltip.css";

export const Tooltip = ({ children, className }) => {
  return (
    <div className={cn("tooltip", className)}>
      <div>{children}</div>
    </div>
  );
};
