import { useContext, useEffect, useState } from "react";

import { GAS_WITHDRAW, SYMBOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { web3Service } from "../../web3/services/Web3Service";
import { Modal } from "../../components/Modal/Modal";
import { InputText } from "../../components/InputText/InputText";
import { advancedFormat } from "../../helpers/numberHelpers";
import { Button } from "../../components/Button/Button";

export const Withdraw = ({ show, close, pool, refresh }) => {
  const [amount, setAmount] = useState("");
  const [candidateMinStake, setCandidateMinStake] = useState("0");
  const [delegatorMinStake, setDelegatorMinStake] = useState("0");
  const { address } = useContext(AddressContext);

  const isValidator = pool?.stakingAddress === address;
  const amountWei = web3Service.toWei(amount);
  const stakeAmount = isValidator ? pool?.stakeAmountValidator : pool?.stakeAmount;
  const newStakingAmount = web3Service.sub(stakeAmount, amountWei);
  const minStake = isValidator ? candidateMinStake : delegatorMinStake;
  const validAmount =
    pool &&
    web3Service.gt(minStake) &&
    web3Service.between(amount, 0, pool.maxWithdrawAllowed) &&
    (web3Service.gte(web3Service.fromWei(newStakingAmount), minStake) || web3Service.isZero(newStakingAmount));
  const maxAmount = web3Service.sub(pool?.maxWithdrawAllowed, minStake);

  const withdraw = async () => {
    await stakingAuRaService.send(
      "withdraw",
      {
        from: address,
        gas: GAS_WITHDRAW,
      },
      close,
      refresh,
      pool.stakingAddress,
      web3Service.toWei(amount)
    );
  };

  const load = async () => {
    setCandidateMinStake(await stakingAuRaService.call("candidateMinStake"));
    setDelegatorMinStake(await stakingAuRaService.call("delegatorMinStake"));
  };

  useEffect(() => {
    load().then();
  }, []);

  return (
    <>
      {show && pool && (
        <Modal onClose={close} title="Withdraw">
          <p>
            You can't leave the staking amount below the minimum ({advancedFormat(minStake).long} {SYMBOL}).
          </p>
          <InputText
            label="Pool"
            value={`#${pool.poolId}: ${pool.poolName || ""} - ${pool.poolDescription}`}
            disabled
            valid={true}
          />
          <InputText
            label="Amount"
            unit="XVM"
            description={`Max: ${advancedFormat(maxAmount).long} ${SYMBOL} or ${
              advancedFormat(pool?.maxWithdrawAllowed).long
            } ${SYMBOL}`}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            valid={validAmount}
          />
          <Button className="full" onClick={withdraw} disabled={!validAmount}>
            Withdraw
          </Button>
        </Modal>
      )}
    </>
  );
};
