import cn from "classnames";
import "./Button.css";

export const Button = ({ children, className, ...props }) => {
  return (
    <button className={cn("button", className)} {...props}>
      {children}
    </button>
  );
};
