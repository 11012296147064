import { useState } from "react";

import "./App.css";
import { AddressContext } from "../../contexts/addressContext";
import { ChainContext } from "../../contexts/chainContext";
import { Header } from "../../components/Header/Header";
import { Home } from "../Home/Home";

export const App = () => {
  const [address, setAddress] = useState(null);
  const [chainId, setChainId] = useState(null);

  return (
    <ChainContext.Provider value={{ chainId, setChainId }}>
      <AddressContext.Provider value={{ address, setAddress }}>
        <div className="app">
          <Header />
          <Home />
        </div>
      </AddressContext.Provider>
    </ChainContext.Provider>
  );
};
