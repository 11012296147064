export const DEBUG = process.env.REACT_APP_DEBUG;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME;
export const RPC_URL = process.env.REACT_APP_RPC_URL;
export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL;
export const CURRENCY = process.env.REACT_APP_CURRENCY;
export const SYMBOL = process.env.REACT_APP_SYMBOL;
export const DECIMALS = process.env.REACT_APP_DECIMALS;
export const ZERO_ADDRESS = process.env.REACT_APP_ZERO_ADDRESS;
export const STAKING_CONTRACT = process.env.REACT_APP_STAKING_CONTRACT;
export const VALIDATOR_CONTRACT = process.env.REACT_APP_VALIDATOR_CONTRACT;
export const BLOCK_REWARD_CONTRACT = process.env.REACT_APP_BLOCK_REWARD_CONTRACT;
export const GAS_ADD_POOL = process.env.REACT_APP_GAS_ADD_POOL;
export const GAS_REMOVE_MY_POOL = process.env.REACT_APP_GAS_REMOVE_MY_POOL;
export const GAS_STAKE = process.env.REACT_APP_GAS_STAKE;
export const GAS_CLAIM_REWARD = process.env.REACT_APP_GAS_CLAIM_REWARD;
export const GAS_ORDER_WITHDRAW = process.env.REACT_APP_GAS_ORDER_WITHDRAW;
export const GAS_WITHDRAW = process.env.REACT_APP_GAS_WITHDRAW;
export const GAS_CLAIM_ORDERED_WITHDRAW = process.env.REACT_APP_GAS_CLAIM_ORDERED_WITHDRAW;
