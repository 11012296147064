import { useCallback, useContext, useEffect, useState } from "react";

import { CHAIN_ID, CHAIN_NAME, ZERO_ADDRESS } from "../../config";
import { ChainContext } from "../../contexts/chainContext";
import { AddressContext } from "../../contexts/addressContext";
import { web3Service } from "../../web3/services/Web3Service";
import { blockRewardAuRaService, stakingAuRaService, validatorAuRaService } from "../../web3/services/ContractService";
import { Container } from "../../components/Container/Container";
import { Progress } from "../../components/Progress/Progress";
import { Pools } from "../../components/Pools/Pools";
import { AddPool } from "../AddPool/AddPool";
import { RemoveMyPool } from "../RemoveMyPool/RemoveMyPool";
import { Stake } from "../Stake/Stake";
import { ClaimReward } from "../ClaimReward/ClaimReward";
import { OrderWithdraw } from "../OrderWithdraw/OrderWithdraw";
import { Withdraw } from "../Withdraw/Withdraw";
import { Box } from "../../components/Box/Box";
import { ClaimOrderedWithdraw } from "../ClaimOrderedWithdraw/ClaimOrderedWithdraw";

export const Home = () => {
  const { chainId } = useContext(ChainContext);
  const { address } = useContext(AddressContext);

  const [readyProgress, setReadyProgress] = useState(false);
  const [readyPools, setReadyPools] = useState(false);
  const [blockNumber, setBlockNumber] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [areStakeAndWithdrawAllowed, setAreStakeAndWithdrawAllowed] = useState(null);
  const [stakingEpoch, setStakingEpoch] = useState(null);
  const [stakingEpochStartBlock, setStakingEpochStartBlock] = useState(null);
  const [stakingEpochEndBlock, setStakingEpochEndBlock] = useState(null);
  const [stakeWithdrawDisallowPeriod, setStakeWithdrawDisallowPeriod] = useState(null);
  const [pools, setPools] = useState([]);
  const [pool, setPool] = useState(null);
  const [totalStake, setTotalStake] = useState("0");
  const [showInactivePools, setShowInactivePools] = useState(false);
  const [showAddPool, setShowAddPool] = useState(false);
  const [showRemoveMyPool, setShowRemoveMyPool] = useState(false);
  const [showStake, setShowStake] = useState(false);
  const [showClaimReward, setShowClaimReward] = useState(false);
  const [showOrderWithdraw, setShowOrderWithdraw] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [addPoolAvailable, setAddPoolAvailable] = useState(null);
  const [stakeAvailable, setStakeAvailable] = useState(null);
  const [showClaimOrderedWithdraw, setShowClaimOrderedWithdraw] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadProgress = useCallback(async () => {
    if (chainId === CHAIN_ID) {
      const newBlockNumber = await web3Service.getBlockNumber();
      setBlockNumber(newBlockNumber);
      const currentBlock = await web3Service.getBlock(newBlockNumber);

      setStakingEpoch(await stakingAuRaService.call("stakingEpoch"));

      const newStakingEpochStartBlock = await stakingAuRaService.call("stakingEpochStartBlock");
      setStakingEpochStartBlock(newStakingEpochStartBlock);
      const startBlock = await web3Service.getBlock(newStakingEpochStartBlock);
      setStartTime(startBlock.timestamp);

      const prevBlock = await web3Service.getBlock(newBlockNumber - 100);

      const blockTime = (currentBlock.timestamp - prevBlock.timestamp) / (currentBlock.number - prevBlock.number);

      const newStakingEpochEndBlock = await stakingAuRaService.call("stakingEpochEndBlock");
      setStakingEpochEndBlock(newStakingEpochEndBlock);
      setEndTime(currentBlock.timestamp + blockTime * (newStakingEpochEndBlock - newBlockNumber));

      setStakeWithdrawDisallowPeriod(await stakingAuRaService.call("stakeWithdrawDisallowPeriod"));
      setAreStakeAndWithdrawAllowed(await stakingAuRaService.call("areStakeAndWithdrawAllowed"));
      setReadyProgress(true);
    }
  }, [chainId]);

  const loadPools = useCallback(async () => {
    setIsLoading(true);
    if (chainId === CHAIN_ID) {
      const lastPoolId = await validatorAuRaService.call("lastPoolId");
      const poolIds = [...Array(lastPoolId * 1).keys()].map((i) => `${i + 1}`);
      const validators = await validatorAuRaService.call("getValidators");
      let newTotalStake = "0";
      let newAddPoolAvailable = true;
      let newStakeAvailable = true;
      const newPools = await Promise.all(
        poolIds.map(async (poolId) => {
          const stakingAddress = await validatorAuRaService.call("stakingAddressById", poolId);
          const epochsToClaimRewardFrom = address
            ? await blockRewardAuRaService.call("epochsToClaimRewardFrom", stakingAddress, address)
            : 0;
          const miningAddress = await validatorAuRaService.call("miningAddressById", poolId);
          const stakeAmountTotal = await stakingAuRaService.call("stakeAmountTotal", poolId);
          newTotalStake = web3Service.add(newTotalStake, stakeAmountTotal);
          if (address === stakingAddress || address === miningAddress) {
            newAddPoolAvailable = false;
          }
          if (address === miningAddress) {
            newStakeAvailable = false;
          }
          const validator = validators.includes(miningAddress);
          return {
            poolId,
            isPoolActive: await stakingAuRaService.call("isPoolActive", poolId),
            poolName: await validatorAuRaService.call("poolName", poolId),
            poolDescription: await validatorAuRaService.call("poolDescription", poolId),
            stakeAmountTotal,
            stakeAmountValidator: await stakingAuRaService.call("stakeAmount", poolId, ZERO_ADDRESS),
            stakeAmount: address ? await stakingAuRaService.call("stakeAmount", poolId, address) : 0,
            stakingAddress,
            epochsToClaimRewardFrom,
            miningAddress,
            orderedWithdrawAmount: address
              ? await stakingAuRaService.call("orderedWithdrawAmount", poolId, address)
              : 0,
            orderedWithdrawAmountValidator: await stakingAuRaService.call(
              "orderedWithdrawAmount",
              poolId,
              ZERO_ADDRESS
            ),
            maxWithdrawOrderAllowed: address
              ? await stakingAuRaService.call("maxWithdrawOrderAllowed", stakingAddress, address)
              : 0,
            maxWithdrawAllowed: address
              ? await stakingAuRaService.call("maxWithdrawAllowed", stakingAddress, address)
              : 0,
            validatorCounter: await validatorAuRaService.call("validatorCounter", miningAddress),
            poolDelegators: await stakingAuRaService.call("poolDelegators", poolId),
            getRewardAmount: address
              ? epochsToClaimRewardFrom.length
                ? await stakingAuRaService.call("getRewardAmount", epochsToClaimRewardFrom, stakingAddress, address)
                : "0"
              : 0,
            orderWithdrawEpoch: address ? await stakingAuRaService.call("orderWithdrawEpoch", poolId, address) : 0,
            orderWithdrawEpochValidator: await stakingAuRaService.call("orderWithdrawEpoch", poolId, ZERO_ADDRESS),
            validator,
          };
        })
      );
      setAddPoolAvailable(newAddPoolAvailable);
      setStakeAvailable(newStakeAvailable);
      setTotalStake(newTotalStake);
      setPools(newPools);
      setReadyPools(true);
    }
    setIsLoading(false);
  }, [chainId, address]);

  const load = useCallback(() => {
    loadProgress().then();
    loadPools().then();
  }, [loadProgress, loadPools]);

  useEffect(() => {
    load();
  }, [load]);

  const progressLength = stakingEpochEndBlock - stakingEpochStartBlock;
  const disallowedFromBlock = stakingEpochEndBlock - stakeWithdrawDisallowPeriod;
  const currentPercentage = Math.round(((blockNumber - stakingEpochStartBlock) / progressLength) * 10000) / 100;
  const disallowedPercentage =
    Math.round(((disallowedFromBlock - stakingEpochStartBlock) / progressLength) * 10000) / 100;
  const linearGradient =
    blockNumber < disallowedFromBlock
      ? `#4a6647 ${currentPercentage}%, #94cc8f ${currentPercentage}%, #94cc8f ${disallowedPercentage}%, #cc8f8f ${disallowedPercentage}%, #cc8f8f ${disallowedPercentage}%`
      : `#4a6647 ${disallowedPercentage}%, #664747 ${disallowedPercentage}%, #664747 ${currentPercentage}%, #c28888 ${currentPercentage}%, #c28888 ${currentPercentage}%`;

  return (
    <div className="home">
      <Container>
        {chainId !== CHAIN_ID ? (
          <Box>Please switch to {CHAIN_NAME}</Box>
        ) : (
          <>
            <Progress
              chainId={chainId}
              readyProgress={readyProgress}
              stakingEpoch={stakingEpoch}
              currentPercentage={currentPercentage}
              areStakeAndWithdrawAllowed={areStakeAndWithdrawAllowed}
              stakingEpochStartBlock={stakingEpochStartBlock}
              stakingEpochEndBlock={stakingEpochEndBlock}
              linearGradient={linearGradient}
              blockNumber={blockNumber}
              startTime={startTime}
              endTime={endTime}
            />

            <Pools
              address={address}
              stakingEpoch={stakingEpoch}
              readyPools={readyPools}
              pools={pools}
              totalStake={totalStake}
              areStakeAndWithdrawAllowed={areStakeAndWithdrawAllowed}
              openAddPool={() => setShowAddPool(true)}
              addPoolAvailable={addPoolAvailable}
              stakeAvailable={stakeAvailable}
              openRemoveMyPool={() => setShowRemoveMyPool(true)}
              openStake={(newPool) => () => {
                setPool(newPool);
                setShowStake(true);
              }}
              openClaimReward={(newPool) => () => {
                setPool(newPool);
                setShowClaimReward(true);
              }}
              openOrderWithdraw={(newPool) => () => {
                setPool(newPool);
                setShowOrderWithdraw(true);
              }}
              openClaimOrderedWithdraw={(newPool) => () => {
                setPool(newPool);
                setShowClaimOrderedWithdraw(true);
              }}
              openWithdraw={(newPool) => () => {
                setPool(newPool);
                setShowWithdraw(true);
              }}
              showInactivePools={showInactivePools}
              onChangeShowInactivePools={(e) => setShowInactivePools(e.target.checked)}
              isLoading={isLoading}
            />

            <AddPool show={showAddPool} close={() => setShowAddPool(false)} refresh={load} />
            <RemoveMyPool show={showRemoveMyPool} close={() => setShowRemoveMyPool(false)} refresh={load} />
            <Stake show={showStake} close={() => setShowStake(false)} pool={pool} refresh={load} />
            <ClaimReward show={showClaimReward} close={() => setShowClaimReward(false)} pool={pool} refresh={load} />
            <OrderWithdraw
              show={showOrderWithdraw}
              close={() => setShowOrderWithdraw(false)}
              pool={pool}
              refresh={load}
            />
            <Withdraw show={showWithdraw} close={() => setShowWithdraw(false)} pool={pool} refresh={load} />
            <ClaimOrderedWithdraw
              show={showClaimOrderedWithdraw}
              close={() => setShowClaimOrderedWithdraw(false)}
              pool={pool}
              refresh={load}
            />
          </>
        )}
      </Container>
    </div>
  );
};
