import cn from "classnames";

import "./Icon.css";

export const Icon = ({ type, className }) => {
  const icons = {
    close: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
        <path
          fillRule="evenodd"
          d="M10.435 8.983l7.261 7.261a1.027 1.027 0 1 1-1.452 1.452l-7.261-7.261-7.262 7.262a1.025 1.025 0 1 1-1.449-1.45l7.262-7.261L.273 1.725A1.027 1.027 0 1 1 1.725.273l7.261 7.261 7.23-7.231a1.025 1.025 0 1 1 1.449 1.45l-7.23 7.23z"
        ></path>
      </svg>
    ),
    arrowDown: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="18" height="18">
        <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
      </svg>
    ),
  };

  return <div className={cn("icon", className)}>{icons[type]}</div>;
};
