import { useContext, useEffect, useState } from "react";

import "./OrderWithdraw.css";
import { GAS_ORDER_WITHDRAW, SYMBOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { web3Service } from "../../web3/services/Web3Service";
import { Modal } from "../../components/Modal/Modal";
import { InputText } from "../../components/InputText/InputText";
import { advancedFormat } from "../../helpers/numberHelpers";
import { Button } from "../../components/Button/Button";
import { InputRadio } from "../../components/InputRadio/InputRadio";

const INCREASE = "increase";
const DECREASE = "decrease";

export const OrderWithdraw = ({ show, close, pool, refresh }) => {
  const [amount, setAmount] = useState("");
  const [direction, setDirection] = useState(INCREASE);
  const [candidateMinStake, setCandidateMinStake] = useState("0");
  const [delegatorMinStake, setDelegatorMinStake] = useState("0");
  const { address } = useContext(AddressContext);

  const isValidator = pool?.stakingAddress === address;
  const amountWei = web3Service.toWei(amount);
  const stakeAmount = isValidator ? pool?.stakeAmountValidator : pool?.stakeAmount;
  const newStakingAmount =
    direction === INCREASE ? web3Service.sub(stakeAmount, amountWei) : web3Service.add(stakeAmount, amountWei);
  const minStake = isValidator ? candidateMinStake : delegatorMinStake;
  const orderedWithdrawAmount = isValidator ? pool?.orderedWithdrawAmountValidator : pool?.orderedWithdrawAmount;
  const validAmount =
    pool &&
    web3Service.gt(minStake) &&
    ((direction === INCREASE && web3Service.between(amount, 0, pool.maxWithdrawOrderAllowed)) ||
      (direction === DECREASE && web3Service.between(amount, 0, orderedWithdrawAmount))) &&
    (web3Service.gte(web3Service.fromWei(newStakingAmount), minStake) || web3Service.isZero(newStakingAmount));
  const maxAmount = web3Service.sub(pool?.maxWithdrawOrderAllowed, minStake);

  const orderWithdraw = async () => {
    await stakingAuRaService.send(
      "orderWithdraw",
      {
        from: address,
        gas: GAS_ORDER_WITHDRAW,
      },
      close,
      refresh,
      pool.stakingAddress,
      web3Service.toWei(direction === INCREASE ? amount : `-${amount}`)
    );
  };

  const load = async () => {
    setCandidateMinStake(await stakingAuRaService.call("candidateMinStake"));
    setDelegatorMinStake(await stakingAuRaService.call("delegatorMinStake"));
  };

  useEffect(() => {
    load().then();
  }, []);

  return (
    <>
      {show && (
        <Modal onClose={close} title="Order withdraw">
          <p>
            When you successfully order a withdrawal, you can only claim ordered withdraw when the current epoch ends.
          </p>
          <p>
            You can't leave the staking amount below the minimum ({advancedFormat(minStake).long} {SYMBOL}).
          </p>
          <InputText
            label="Pool"
            value={`#${pool.poolId}: ${pool.poolName || ""} - ${pool.poolDescription}`}
            disabled
            valid={true}
          />
          <div className="direction">
            <InputRadio
              name="direction"
              value={DECREASE}
              checked={direction === DECREASE}
              onChange={(e) => setDirection(e.target.value)}
              caption="Decrease"
            />
            <InputRadio
              name="direction"
              value={INCREASE}
              checked={direction === INCREASE}
              onChange={(e) => setDirection(e.target.value)}
              caption="Increase"
            />
          </div>
          <InputText
            label="Amount"
            unit="XVM"
            description={`Decrease limit: ${advancedFormat(orderedWithdrawAmount).long} ${SYMBOL}, Increase limit: ${
              advancedFormat(maxAmount).long
            } ${SYMBOL} or ${advancedFormat(pool.maxWithdrawOrderAllowed).long} ${SYMBOL}`}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            valid={validAmount}
          />
          <Button className="full" onClick={orderWithdraw} disabled={!validAmount}>
            Order withdraw
          </Button>
        </Modal>
      )}
    </>
  );
};
