import { useContext, useEffect, useState } from "react";

import { GAS_ADD_POOL, SYMBOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { web3Service } from "../../web3/services/Web3Service";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { advancedFormat } from "../../helpers/numberHelpers";
import { InputText } from "../../components/InputText/InputText";
import { Button } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";

export const AddPool = ({ show, close, refresh }) => {
  const [amount, setAmount] = useState("");
  const [miningAddress, setMiningAddress] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [candidateMinStake, setCandidateMinStake] = useState("0");
  const { address } = useContext(AddressContext);

  const addPool = async () => {
    await stakingAuRaService.send(
      "addPool",
      {
        from: address,
        value: web3Service.toWei(amount),
        gas: GAS_ADD_POOL,
      },
      close,
      refresh,
      0,
      miningAddress,
      name,
      description
    );
  };

  const validAmount = web3Service.gt(candidateMinStake) && web3Service.gte(amount, candidateMinStake);
  const validMiningAddress = web3Service.isAddress(miningAddress);
  const validName = name.length > 0;
  const validDescription = true;

  const load = async () => {
    setCandidateMinStake(await stakingAuRaService.call("candidateMinStake"));
  };

  useEffect(() => {
    load().then();
  }, []);

  return (
    <>
      {show && (
        <Modal onClose={close} title="Add pool">
          <InputText
            label="Amount"
            unit="XVM"
            description={`Min: ${advancedFormat(candidateMinStake).long} ${SYMBOL}`}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            valid={validAmount}
          />
          <InputText
            label="Your Mining Address"
            value={miningAddress}
            onChange={(e) => setMiningAddress(e.target.value)}
            valid={validMiningAddress}
          />
          <InputText label="Your Pool Name" value={name} onChange={(e) => setName(e.target.value)} valid={validName} />
          <InputText
            label="Your Pool Short Description (optional)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            valid={validDescription}
          />
          <Button
            className="full"
            onClick={addPool}
            disabled={!validAmount || !validMiningAddress || !validName || !validDescription}
          >
            Add pool
          </Button>
        </Modal>
      )}
    </>
  );
};
