import { useContext, useEffect, useState } from "react";

import { GAS_STAKE, SYMBOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { web3Service } from "../../web3/services/Web3Service";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { Modal } from "../../components/Modal/Modal";
import { InputText } from "../../components/InputText/InputText";
import { advancedFormat } from "../../helpers/numberHelpers";
import { Button } from "../../components/Button/Button";

export const Stake = ({ show, close, pool, refresh }) => {
  const [amount, setAmount] = useState("");
  const [delegatorMinStake, setDelegatorMinStake] = useState("0");
  const { address } = useContext(AddressContext);

  const stake = async () => {
    await stakingAuRaService.send(
      "stake",
      {
        from: address,
        value: web3Service.toWei(amount),
        gas: GAS_STAKE,
      },
      close,
      refresh,
      pool.stakingAddress,
      0
    );
  };

  const minimumAmount = web3Service.sub(delegatorMinStake, pool?.stakeAmount || "0");
  const validAmount =
    web3Service.gt(delegatorMinStake) && web3Service.gte(amount, minimumAmount) && web3Service.gt(amount);

  const load = async () => {
    setDelegatorMinStake(await stakingAuRaService.call("delegatorMinStake"));
  };

  useEffect(() => {
    load().then();
  }, []);

  return (
    <>
      {show && (
        <Modal onClose={close} title="Stake">
          <InputText
            label="Pool"
            value={`#${pool.poolId}: ${pool.poolName || ""} - ${pool.poolDescription}`}
            disabled
            valid={true}
          />
          <InputText
            label="Amount"
            unit="XVM"
            description={
              web3Service.gt(minimumAmount) ? `Minimum: ${advancedFormat(minimumAmount).long} ${SYMBOL}` : ""
            }
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            valid={validAmount}
          />
          <Button className="full" onClick={stake} disabled={!validAmount}>
            Stake
          </Button>
        </Modal>
      )}
    </>
  );
};
