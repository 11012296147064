import "./Header.css";
import { Container } from "../Container/Container";
import { Logo } from "../Logo/Logo";
import { Wallet } from "../../containers/Wallet/Wallet";

export const Header = () => {
  return (
    <div className="header-container">
      <Container>
        <div className="header">
          <Logo />
          <Wallet />
        </div>
      </Container>
    </div>
  );
};
