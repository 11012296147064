import { Fragment, useState } from "react";
import cn from "classnames";

import "./Pools.css";
import { SYMBOL } from "../../config";
import { advancedFormat, percentageFormat } from "../../helpers/numberHelpers";
import { web3Service } from "../../web3/services/Web3Service";
import { Box } from "../Box/Box";
import { Button } from "../Button/Button";
import { Tooltip } from "../Tooltip/Tooltip";

export const Pools = ({
  address,
  readyPools,
  pools,
  areStakeAndWithdrawAllowed,
  openAddPool,
  openRemoveMyPool,
  openStake,
  openClaimReward,
  openOrderWithdraw,
  openWithdraw,
  showInactivePools,
  onChangeShowInactivePools,
  totalStake,
  addPoolAvailable,
  openClaimOrderedWithdraw,
  stakeAvailable,
  stakingEpoch,
  isLoading,
}) => {
  const [detailedPoolId, setDetailedPoolId] = useState(0);

  return (
    <Box>
      {readyPools && (
        <div className="pools-header">
          <div>
            Total stake:{" "}
            <span className="bold">
              {advancedFormat(totalStake).long} {SYMBOL}
            </span>
          </div>
          {!isLoading && address && areStakeAndWithdrawAllowed && addPoolAvailable && (
            <Button className="small" onClick={openAddPool}>
              Add pool
            </Button>
          )}
        </div>
      )}
      {!readyPools ? (
        "Loading..."
      ) : pools?.length ? (
        <div className="pools-list">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th className="left">Name</th>
                <th className="left">Description</th>
                <th className="left">Validator's Stake ({SYMBOL})</th>
                <th className="right">Total Stake ({SYMBOL})</th>
                <th>Stakes ratio (%)</th>
                <th>Epochs as Validator</th>
                <th>Delegators</th>
                <th>Your Stake ({SYMBOL})</th>
                <th>Ordered Amount ({SYMBOL})</th>
                <th>Available Reward ({SYMBOL})</th>
              </tr>
              <tr>
                <th></th>
                <th colSpan={4} className="left">
                  <div>Staking address</div>
                  <div>Mining address</div>
                </th>
                <th colSpan={6}></th>
              </tr>
            </thead>

            <tbody>
              {pools
                ?.filter((item) => showInactivePools || item.isPoolActive)
                ?.map?.((pool, index) => {
                  const isValidator = address === pool.stakingAddress;
                  const getRewardAmount = advancedFormat(pool.getRewardAmount);
                  const stakeAmountValidator = advancedFormat(pool.stakeAmountValidator);
                  const stakeAmountTotal = advancedFormat(pool.stakeAmountTotal);
                  const stakeAmount = advancedFormat(isValidator ? pool.stakeAmountValidator : pool.stakeAmount);
                  const orderedWithdrawAmount = advancedFormat(
                    isValidator ? pool.orderedWithdrawAmountValidator : pool.orderedWithdrawAmount
                  );

                  return (
                    <Fragment key={pool.poolId}>
                      <tr
                        className={cn({ inactive: !pool.isPoolActive, even: index % 2 === 1 })}
                        onClick={() => setDetailedPoolId(detailedPoolId === pool.poolId ? 0 : pool.poolId)}
                      >
                        <td className="center bold">{pool.poolId}</td>
                        <td className="bold">{pool.poolName}</td>
                        <td className="bold">{pool.poolDescription}</td>
                        <td colSpan={2}>
                          <div className="stakes-cell">
                            <div title={stakeAmountValidator.title}>{stakeAmountValidator.short}</div>
                            <div className="right" title={stakeAmountTotal.title}>
                              {stakeAmountTotal.short}
                            </div>
                            {web3Service.gt(pool.stakeAmountTotal) && (
                              <div
                                className="stakes-progress"
                                style={{
                                  background: `linear-gradient(90deg, orange ${percentageFormat(
                                    web3Service.fromWei(
                                      web3Service.percentage(pool.stakeAmountValidator, pool.stakeAmountTotal)
                                    )
                                  )}%, yellow ${percentageFormat(
                                    web3Service.fromWei(
                                      web3Service.percentage(pool.stakeAmountValidator, pool.stakeAmountTotal)
                                    )
                                  )}%)`,
                                }}
                              />
                            )}
                          </div>
                        </td>
                        <td className="center">
                          {percentageFormat(
                            web3Service.fromWei(web3Service.percentage(pool.stakeAmountTotal, totalStake))
                          )}
                        </td>
                        <td className="center">{pool.validatorCounter}</td>
                        <td className="center">{pool.poolDelegators.length}</td>
                        <td className="center" title={isLoading ? "" : stakeAmount.title}>
                          {!isLoading && stakeAmount.short}
                        </td>
                        <td className="center" title={isLoading ? "" : orderedWithdrawAmount.title}>
                          {!isLoading && orderedWithdrawAmount.short}
                        </td>
                        <td className="center" title={isLoading ? "" : getRewardAmount.long}>
                          {!isLoading && getRewardAmount.short}
                        </td>
                      </tr>
                      <tr className={cn("row-detail", { inactive: !pool.isPoolActive, even: index % 2 === 1 })}>
                        <td></td>
                        <td colSpan={10}>
                          <div className="pool-detail">
                            <div>
                              <div>{pool.stakingAddress}</div>
                              <div>
                                {pool.miningAddress}
                                {pool.validator && (
                                  <span className="tooltip-trigger">
                                    <span className="pool-validator">&#10004;</span>
                                    <Tooltip>The pool is a validator in this epoch</Tooltip>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="actions">
                              {!isLoading && address && areStakeAndWithdrawAllowed && (
                                <>
                                  {pool.isPoolActive && stakeAvailable && (
                                    <Button className="small" onClick={openStake(pool)}>
                                      Stake
                                    </Button>
                                  )}
                                  {web3Service.gt(pool.maxWithdrawAllowed) && (
                                    <Button className="small" onClick={openWithdraw(pool)}>
                                      Withdraw
                                    </Button>
                                  )}
                                  {(web3Service.gt(pool.orderedWithdrawAmount) ||
                                    web3Service.gt(pool.maxWithdrawOrderAllowed)) && (
                                    <Button className="small" onClick={openOrderWithdraw(pool)}>
                                      Order withdraw
                                    </Button>
                                  )}
                                  {!isValidator &&
                                    web3Service.gt(pool.orderedWithdrawAmount) &&
                                    pool.orderWithdrawEpoch * 1 < stakingEpoch * 1 && (
                                      <Button className="small" onClick={openClaimOrderedWithdraw(pool)}>
                                        Claim ordered withdraw
                                      </Button>
                                    )}
                                  {isValidator &&
                                    web3Service.gt(pool.orderedWithdrawAmountValidator) &&
                                    pool.orderWithdrawEpochValidator * 1 < stakingEpoch * 1 && (
                                      <Button className="small" onClick={openClaimOrderedWithdraw(pool)}>
                                        Claim ordered withdraw
                                      </Button>
                                    )}
                                  {pool.epochsToClaimRewardFrom?.length > 0 && (
                                    <Button className="small" onClick={openClaimReward(pool)}>
                                      Claim reward
                                    </Button>
                                  )}
                                  {pool.isPoolActive && isValidator && (
                                    <Button className="small" onClick={openRemoveMyPool}>
                                      Remove my pool
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        "No data..."
      )}

      {readyPools && (
        <div className="pools-inactive">
          <label>
            <input type="checkbox" checked={showInactivePools} onChange={onChangeShowInactivePools} /> Show pools with inactive stake
          </label>
        </div>
      )}
    </Box>
  );
};
