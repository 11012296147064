import { useContext } from "react";

import { GAS_CLAIM_REWARD, SYMBOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { Button } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { InputText } from "../../components/InputText/InputText";
import { advancedFormat } from "../../helpers/numberHelpers";

export const ClaimReward = ({ show, close, pool, refresh }) => {
  const { address } = useContext(AddressContext);

  const claimReward = async () => {
    await stakingAuRaService.send(
      "claimReward",
      {
        from: address,
        gas: GAS_CLAIM_REWARD * pool.epochsToClaimRewardFrom.length,
      },
      close,
      refresh,
      pool.epochsToClaimRewardFrom,
      pool.stakingAddress
    );
  };

  return (
    <>
      {show && (
        <Modal onClose={close} title="Claim reward">
          <InputText
            label="Pool"
            value={`#${pool.poolId}: ${pool.poolName} - ${pool.poolName}`}
            disabled
            valid={true}
          />
          <InputText
            label="Epochs to claim reward from"
            value={pool.epochsToClaimRewardFrom.join(", ")}
            disabled
            valid={true}
          />
          <InputText
            label="Available reward"
            value={advancedFormat(pool.getRewardAmount).long}
            disabled
            valid={true}
            unit={SYMBOL}
          />
          <Button className="full" onClick={claimReward}>
            Claim reward
          </Button>
        </Modal>
      )}
    </>
  );
};
