import { web3Service } from "../web3/services/Web3Service";

export const percentageFormat = (number) => new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(number);
export const intFormat = (number) => new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(number);

export const advancedFormat = (numberWei) => {
  try {
    const number = web3Service.fromWei(numberWei);
    const decimalPoint = number.indexOf(".");
    const fractionPart = decimalPoint > -1 ? number.substring(decimalPoint + 1) : "";
    const wholeNumberPart = decimalPoint > -1 ? number.substring(0, decimalPoint) : number;
    const wholeNumberPartFormatted = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
      wholeNumberPart * 1
    );
    const long = fractionPart !== "" ? `${wholeNumberPartFormatted}.${fractionPart}` : wholeNumberPartFormatted;
    const short =
      fractionPart !== "" ? `${wholeNumberPartFormatted}.${fractionPart.substring(0, 2)}` : wholeNumberPartFormatted;
    const suffix = long === short ? "" : "...";
    return {
      long,
      short: `${short}${suffix}`,
      title: long !== short ? long : "",
    };
  } catch (e) {}
  return { long: "", short: "", title: "" };
};
