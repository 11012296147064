import "./Progress.css";
import { Box } from "../Box/Box";
import { intFormat } from "../../helpers/numberHelpers";

export const Progress = ({
  readyProgress,
  stakingEpoch,
  currentPercentage,
  areStakeAndWithdrawAllowed,
  stakingEpochStartBlock,
  stakingEpochEndBlock,
  linearGradient,
  blockNumber,
  startTime,
  endTime,
}) => (
  <Box>
    {!readyProgress ? (
      "Loading..."
    ) : (
      <>
        <div className="progress-wrapper">
          <div>
            <div>
              Staking Epoch: <span className="bold">{stakingEpoch}</span>
            </div>
            <div>
              Current Epoch: <span className="bold">{currentPercentage}%</span>
            </div>
          </div>
          <div className={areStakeAndWithdrawAllowed ? "staking-allowed" : "staking-disallowed"}>
            Staking {areStakeAndWithdrawAllowed ? "Allowed" : "Disallowed"}
          </div>
        </div>
        <div className="progress-text">
          <div>
            <div>
              Start Time: <span className="bold">{new Date(startTime * 1000).toLocaleString()}</span>
            </div>
            <div>
              Start Block: <span className="bold">{intFormat(stakingEpochStartBlock)}</span>
            </div>
          </div>
          <div className="center">
            Current Block: <span className="bold">{intFormat(blockNumber)}</span>
          </div>
          <div className="right">
            <div>
              Estimated End Time: <span className="bold">{new Date(endTime * 1000).toLocaleString()}</span>
            </div>
            <div>
              End Block: <span className="bold">{intFormat(stakingEpochEndBlock)}</span>
            </div>
          </div>
        </div>
        <div
          className="progress-top"
          style={{
            marginLeft: `calc(${currentPercentage}% - 8px)`,
          }}
        />
        <div
          className="progress"
          style={{
            background: `linear-gradient(90deg, ${linearGradient})`,
          }}
        ></div>
        <div
          className="progress-bottom"
          style={{
            marginLeft: `calc(${currentPercentage}% - 8px)`,
          }}
        ></div>
        <div className="progress-text">
          <div>
            <span className="bold">{intFormat(blockNumber - stakingEpochStartBlock)}</span> blocks passed
          </div>
          <div>
            <span className="bold">{intFormat(stakingEpochEndBlock - blockNumber)}</span> blocks remaining
          </div>
        </div>
      </>
    )}
  </Box>
);
