import cn from "classnames";

import "./InputText.css";

export const InputText = ({ className, label, unit, description, valid, error, ...props }) => {
  return (
    <label className="input-text">
      <div>{label}</div>
      <div className="input-text-wrapper">
        <input className={cn({ "input-valid": valid })} type="text" {...props} />
        <div className="input-text-unit">{unit}</div>
      </div>
      <div>{description}</div>
      <div className="input-text-error">{error}</div>
    </label>
  );
};
