import { useContext } from "react";

import { GAS_CLAIM_ORDERED_WITHDRAW, SYMBOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { Button } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { InputText } from "../../components/InputText/InputText";
import { advancedFormat } from "../../helpers/numberHelpers";

export const ClaimOrderedWithdraw = ({ show, close, pool, refresh }) => {
  const { address } = useContext(AddressContext);

  const action = async () => {
    await stakingAuRaService.send(
      "claimOrderedWithdraw",
      {
        from: address,
        gas: GAS_CLAIM_ORDERED_WITHDRAW,
      },
      close,
      refresh,
      pool.stakingAddress
    );
  };

  return (
    <>
      {show && (
        <Modal onClose={close} title="Claim ordered withdraw">
          <InputText
            label="Pool"
            value={`#${pool.poolId}: ${pool.poolName} - ${pool.poolName}`}
            disabled
            valid={true}
          />
          <InputText
            label="Ordered amount"
            value={advancedFormat(address === pool.stakingAddress ? pool.orderedWithdrawAmountValidator : pool.orderedWithdrawAmount).long}
            disabled
            valid={true}
            unit={SYMBOL}
          />
          <Button className="full" onClick={action}>
            Claim ordered withdraw
          </Button>
        </Modal>
      )}
    </>
  );
};
