export const stakingAuRaAbi = [
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "poolDelegatorIndex",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "initialValidatorStake",
    inputs: [{ type: "uint256", name: "_totalAmount" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakeAmountByCurrentEpoch",
    inputs: [
      { type: "uint256", name: "_poolId" },
      { type: "address", name: "_delegatorOrZero" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "poolDelegatorInactiveIndex",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "removePools",
    inputs: [],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "bool", name: "" }],
    name: "rewardWasTaken",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
      { type: "uint256", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "setStakingEpochStartBlock",
    inputs: [{ type: "uint256", name: "_blockNumber" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "moveStake",
    inputs: [
      { type: "address", name: "_fromPoolStakingAddress" },
      { type: "address", name: "_toPoolStakingAddress" },
      { type: "uint256", name: "_amount" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakeFirstEpoch",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakeAmountTotal",
    inputs: [{ type: "uint256", name: "" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "setDelegatorMinStake",
    inputs: [{ type: "uint256", name: "_minStake" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256[]", name: "result" }],
    name: "getDelegatorPools",
    inputs: [
      { type: "address", name: "_delegator" },
      { type: "uint256", name: "_offset" },
      { type: "uint256", name: "_length" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "clearUnremovableValidator",
    inputs: [{ type: "uint256", name: "_unremovablePoolId" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakeLastEpoch",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "bool", name: "" }],
    name: "isInitialized",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "claimReward",
    inputs: [
      { type: "uint256[]", name: "_stakingEpochs" },
      { type: "address", name: "_poolStakingAddress" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakeAmount",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "orderedWithdrawAmountTotal",
    inputs: [{ type: "uint256", name: "" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "setCandidateMinStake",
    inputs: [{ type: "uint256", name: "_minStake" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "withdrawPortis",
    inputs: [{ type: "address", name: "_dest" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "address[]", name: "" }],
    name: "poolDelegators",
    inputs: [{ type: "uint256", name: "_poolId" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "candidateMinStake",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256[]", name: "" }],
    name: "getPools",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "maxWithdrawAllowed",
    inputs: [
      { type: "address", name: "_poolStakingAddress" },
      { type: "address", name: "_staker" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "delegatorStakeSnapshot",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
      { type: "uint256", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "poolInactiveIndex",
    inputs: [{ type: "uint256", name: "" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakingEpochStartBlock",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "lastChangeBlock",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakingEpoch",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "getDelegatorPoolsLength",
    inputs: [{ type: "address", name: "_delegator" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakingEpochEndBlock",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "addUnremovableValidator",
    inputs: [{ type: "uint256", name: "_poolId" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "maxWithdrawOrderAllowed",
    inputs: [
      { type: "address", name: "_poolStakingAddress" },
      { type: "address", name: "_staker" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [
      { type: "uint256[]", name: "likelihoods" },
      { type: "uint256", name: "sum" },
    ],
    name: "getPoolsLikelihood",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakeWithdrawDisallowPeriod",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "poolToBeElectedIndex",
    inputs: [{ type: "uint256", name: "" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "address[]", name: "" }],
    name: "poolDelegatorsInactive",
    inputs: [{ type: "uint256", name: "_poolId" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "removePool",
    inputs: [{ type: "uint256", name: "_poolId" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256[]", name: "" }],
    name: "getPoolsToBeElected",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "payable",
    payable: true,
    outputs: [],
    name: "stake",
    inputs: [
      { type: "address", name: "_toPoolStakingAddress" },
      { type: "uint256", name: "_amount" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "address", name: "" }],
    name: "governanceContract",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "orderWithdraw",
    inputs: [
      { type: "address", name: "_poolStakingAddress" },
      { type: "int256", name: "_amount" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "bool", name: "" }],
    name: "isPoolActive",
    inputs: [{ type: "uint256", name: "_poolId" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "poolToBeRemovedIndex",
    inputs: [{ type: "uint256", name: "" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "initialize",
    inputs: [
      { type: "address", name: "_validatorSetContract" },
      { type: "address", name: "_governanceContract" },
      { type: "uint256[]", name: "_initialIds" },
      { type: "uint256", name: "_delegatorMinStake" },
      { type: "uint256", name: "_candidateMinStake" },
      { type: "uint256", name: "_stakingEpochDuration" },
      { type: "uint256", name: "_stakingEpochStartBlock" },
      { type: "uint256", name: "_stakeWithdrawDisallowPeriod" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "poolIndex",
    inputs: [{ type: "uint256", name: "" }],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "claimOrderedWithdraw",
    inputs: [{ type: "address", name: "_poolStakingAddress" }],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256[]", name: "" }],
    name: "getPoolsToBeRemoved",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "orderWithdrawEpoch",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "stakingEpochDuration",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "delegatorMinStake",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256[]", name: "" }],
    name: "getPoolsInactive",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "address", name: "" }],
    name: "validatorSetContract",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "orderedWithdrawAmount",
    inputs: [
      { type: "uint256", name: "" },
      { type: "address", name: "" },
    ],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "payable",
    payable: true,
    outputs: [{ type: "uint256", name: "" }],
    name: "addPool",
    inputs: [
      { type: "uint256", name: "_amount" },
      { type: "address", name: "_miningAddress" },
      { type: "string", name: "_name" },
      { type: "string", name: "_description" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "removeMyPool",
    inputs: [],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "incrementStakingEpoch",
    inputs: [],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "" }],
    name: "MAX_CANDIDATES",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    payable: false,
    outputs: [],
    name: "withdraw",
    inputs: [
      { type: "address", name: "_fromPoolStakingAddress" },
      { type: "uint256", name: "_amount" },
    ],
    constant: false,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "bool", name: "" }],
    name: "areStakeAndWithdrawAllowed",
    inputs: [],
    constant: true,
  },
  {
    type: "function",
    stateMutability: "view",
    payable: false,
    outputs: [{ type: "uint256", name: "rewardSum" }],
    name: "getRewardAmount",
    inputs: [
      { type: "uint256[]", name: "_stakingEpochs" },
      { type: "address", name: "_poolStakingAddress" },
      { type: "address", name: "_staker" },
    ],
    constant: true,
  },
  { type: "fallback", stateMutability: "payable", payable: true },
  {
    type: "event",
    name: "ClaimedReward",
    inputs: [
      { type: "address", name: "fromPoolStakingAddress", indexed: true },
      { type: "address", name: "staker", indexed: true },
      { type: "uint256", name: "stakingEpoch", indexed: true },
      { type: "uint256", name: "nativeCoinsAmount", indexed: false },
      { type: "uint256", name: "fromPoolId", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "AddedPool",
    inputs: [
      { type: "address", name: "poolStakingAddress", indexed: true },
      { type: "address", name: "poolMiningAddress", indexed: true },
      { type: "uint256", name: "poolId", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ClaimedOrderedWithdrawal",
    inputs: [
      { type: "address", name: "fromPoolStakingAddress", indexed: true },
      { type: "address", name: "staker", indexed: true },
      { type: "uint256", name: "stakingEpoch", indexed: true },
      { type: "uint256", name: "amount", indexed: false },
      { type: "uint256", name: "fromPoolId", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MovedStake",
    inputs: [
      { type: "address", name: "fromPoolStakingAddress", indexed: false },
      { type: "address", name: "toPoolStakingAddress", indexed: true },
      { type: "address", name: "staker", indexed: true },
      { type: "uint256", name: "stakingEpoch", indexed: true },
      { type: "uint256", name: "amount", indexed: false },
      { type: "uint256", name: "fromPoolId", indexed: false },
      { type: "uint256", name: "toPoolId", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OrderedWithdrawal",
    inputs: [
      { type: "address", name: "fromPoolStakingAddress", indexed: true },
      { type: "address", name: "staker", indexed: true },
      { type: "uint256", name: "stakingEpoch", indexed: true },
      { type: "int256", name: "amount", indexed: false },
      { type: "uint256", name: "fromPoolId", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PlacedStake",
    inputs: [
      { type: "address", name: "toPoolStakingAddress", indexed: true },
      { type: "address", name: "staker", indexed: true },
      { type: "uint256", name: "stakingEpoch", indexed: true },
      { type: "uint256", name: "amount", indexed: false },
      { type: "uint256", name: "toPoolId", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "WithdrewStake",
    inputs: [
      { type: "address", name: "fromPoolStakingAddress", indexed: true },
      { type: "address", name: "staker", indexed: true },
      { type: "uint256", name: "stakingEpoch", indexed: true },
      { type: "uint256", name: "amount", indexed: false },
      { type: "uint256", name: "fromPoolId", indexed: false },
    ],
    anonymous: false,
  },
];
