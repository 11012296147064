import { useContext } from "react";

import { GAS_REMOVE_MY_POOL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { stakingAuRaService } from "../../web3/services/ContractService";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";

export const RemoveMyPool = ({ show, close, refresh }) => {
  const { address } = useContext(AddressContext);

  const removeMyPool = async () => {
    await stakingAuRaService.send(
      "removeMyPool",
      {
        from: address,
        gas: GAS_REMOVE_MY_POOL,
      },
      close,
      refresh
    );
  };

  return (
    <>
      {show && (
        <Modal onClose={close} title="Remove my pool">
          <Button className="full" onClick={removeMyPool}>
            Remove my pool
          </Button>
        </Modal>
      )}
    </>
  );
};
