import { web3Service } from "./Web3Service";
import { BLOCK_REWARD_CONTRACT, DEBUG, STAKING_CONTRACT, VALIDATOR_CONTRACT } from "../../config";
import { blockRewardAuRaAbi } from "../abis/blockRewardAuRaAbi";
import { validatorAuRaAbi } from "../abis/validatorAuRaAbi";
import { stakingAuRaAbi } from "../abis/stakingAuRaAbi";

class ContractService {
  constructor(abi, contractAddress) {
    this.abi = abi;
    this.contract = web3Service.web3
      ? new web3Service.web3.eth.Contract(this.abi, web3Service.web3.utils.toChecksumAddress(contractAddress))
      : null;
  }

  call = async (method, ...args) => {
    try {
      const response = await this.contract.methods[method](...args).call();
      if (response) {
        DEBUG && console.debug(`ContractService.call.${method}.ok`, response);
        return response;
      } else {
        DEBUG && console.debug(`ContractService.call.${method}.error`, response);
      }
    } catch (e) {
      DEBUG && console.error(`ContractService.call.${method}.exception`, e);
    }
    return null;
  };

  send = async (method, options, onTransactionHash, onReceipt, ...args) => {
    try {
      const response = await this.contract.methods[method](...args)
        .send(options)
        .on("transactionHash", (data) => {
          console.debug(`ContractService.send.${method}.transactionHash`, data);
          onTransactionHash(data);
        })
        .on("receipt", (data) => {
          console.debug(`ContractService.send.${method}.receipt`, data);
          onReceipt(data);
        });
      if (response) {
        console.debug(`ContractService.send.${method}.ok`, response);
        return response;
      } else {
        console.debug(`ContractService.send.${method}.error`, response);
      }
    } catch (e) {
      console.error(`ContractService.send.${method}.exception`, e);
    }
    return null;
  };
}

export const blockRewardAuRaService = new ContractService(blockRewardAuRaAbi, BLOCK_REWARD_CONTRACT);
export const validatorAuRaService = new ContractService(validatorAuRaAbi, VALIDATOR_CONTRACT);
export const stakingAuRaService = new ContractService(stakingAuRaAbi, STAKING_CONTRACT);
