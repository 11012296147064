import "./Modal.css";
import { Icon } from "../Icon/Icon";

export const Modal = ({ onClose, children, title }) => {
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-title">
          <div>{title}</div>
          <div className="modal-close" onClick={onClose}>
            <Icon type="close" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
