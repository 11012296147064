import { useCallback, useContext, useEffect } from "react";

import "./Wallet.css";
import { CHAIN_ID, CHAIN_NAME, EXPLORER_URL } from "../../config";
import { AddressContext } from "../../contexts/addressContext";
import { ChainContext } from "../../contexts/chainContext";
import { web3Service } from "../../web3/services/Web3Service";
import { addressShortener } from "../../helpers/stringHelpers";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { Button } from "../../components/Button/Button";
import { Link } from "../../components/Link/Link";

export const Wallet = () => {
  const { address, setAddress } = useContext(AddressContext);
  const { chainId, setChainId } = useContext(ChainContext);

  const refresh = useCallback(() => {
    setAddress(web3Service.address);
    setChainId(web3Service.chainId);
  }, [setAddress, setChainId]);

  useEffect(() => {
    (async () => {
      if (web3Service.web3Modal.cachedProvider) {
        await web3Service.connect();
      }
      refresh();
      web3Service.subscribe(refresh);
    })();
  }, [refresh]);

  const tooltip = !window.ethereum && <Tooltip className="bottom">Please install MetaMask first!</Tooltip>;

  return (
    <div className="wallet">
      {chainId !== CHAIN_ID ? (
        <Button className="small tooltip-trigger" onClick={web3Service.switchChain} disabled={!window.ethereum}>
          Switch to {CHAIN_NAME}
          {tooltip}
        </Button>
      ) : address ? (
        <div className="wallet-address">
          {CHAIN_NAME} -{" "}
          <Link href={`${EXPLORER_URL}/address/${address}`} target="_blank" rel="noreferrer noopener" title={address}>
            {addressShortener(address)}
          </Link>
        </div>
      ) : (
        <Button className="small tooltip-trigger" onClick={web3Service.connect} disabled={!window.ethereum}>
          Connect
          {tooltip}
        </Button>
      )}
    </div>
  );
};
