export const blockRewardAuRaAbi = [
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "snapshotPoolTotalStakeAmount",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_stakingEpoch",
        type: "uint256",
      },
      {
        name: "_poolId",
        type: "uint256",
      },
    ],
    name: "getValidatorReward",
    outputs: [
      {
        name: "nativeReward",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_bridgesAllowed",
        type: "address[]",
      },
    ],
    name: "setErcToNativeBridgesAllowed",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "epochPoolNativeReward",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_rewardToDistribute",
        type: "uint256",
      },
      {
        name: "_blocksCreatedShareNum",
        type: "uint256[]",
      },
      {
        name: "_blocksCreatedShareDenom",
        type: "uint256",
      },
      {
        name: "_stakingEpoch",
        type: "uint256",
      },
    ],
    name: "currentPoolRewards",
    outputs: [
      {
        name: "",
        type: "uint256[]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "addBridgeNativeRewardReceivers",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "blockRewardContractId",
    outputs: [
      {
        name: "",
        type: "bytes4",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_stakingContract",
        type: "address",
      },
      {
        name: "_stakingEpoch",
        type: "uint256",
      },
      {
        name: "_totalRewardShareNum",
        type: "uint256",
      },
      {
        name: "_totalRewardShareDenom",
        type: "uint256",
      },
      {
        name: "_validators",
        type: "uint256[]",
      },
    ],
    name: "currentNativeRewardToDistribute",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "mintedForAccountInBlock",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "isInitialized",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "NATIVE_COIN_INFLATION_RATE",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_poolId",
        type: "uint256",
      },
    ],
    name: "validatorRewardPercent",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
    ],
    name: "mintedForAccount",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "ercToNativeBridgesAllowed",
    outputs: [
      {
        name: "",
        type: "address[]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "mintedInBlock",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_nativeCoins",
        type: "uint256",
      },
      {
        name: "_to",
        type: "address",
      },
    ],
    name: "transferReward",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_validatorSet",
        type: "address",
      },
      {
        name: "_prevBlockReward",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_poolStakingAddress",
        type: "address",
      },
      {
        name: "_staker",
        type: "address",
      },
    ],
    name: "epochsToClaimRewardFrom",
    outputs: [
      {
        name: "epochsToClaimFrom",
        type: "uint256[]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "addBridgeNativeFeeReceivers",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "mintedTotally",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_stakingEpoch",
        type: "uint256",
      },
      {
        name: "_delegatorStaked",
        type: "uint256",
      },
      {
        name: "_validatorStaked",
        type: "uint256",
      },
      {
        name: "_totalStaked",
        type: "uint256",
      },
      {
        name: "_poolReward",
        type: "uint256",
      },
    ],
    name: "delegatorShare",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "snapshotPoolValidatorStakeAmount",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_stakingEpoch",
        type: "uint256",
      },
      {
        name: "_validatorStaked",
        type: "uint256",
      },
      {
        name: "_totalStaked",
        type: "uint256",
      },
      {
        name: "_poolReward",
        type: "uint256",
      },
    ],
    name: "validatorShare",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_delegatorStake",
        type: "uint256",
      },
      {
        name: "_stakingEpoch",
        type: "uint256",
      },
      {
        name: "_poolId",
        type: "uint256",
      },
    ],
    name: "getDelegatorReward",
    outputs: [
      {
        name: "nativeReward",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "uint256",
      },
      {
        name: "",
        type: "bytes",
      },
    ],
    name: "onTokenTransfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "extraReceiversQueueSize",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_amount",
        type: "uint256",
      },
      {
        name: "_receiver",
        type: "address",
      },
    ],
    name: "addExtraReceiver",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "bridgeNativeReward",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "nativeRewardUndistributed",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
    ],
    name: "mintedTotallyByBridge",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "clearBlocksCreated",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "validatorMinRewardPercent",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "validatorSetContract",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_poolId",
        type: "uint256",
      },
    ],
    name: "epochsPoolGotRewardFor",
    outputs: [
      {
        name: "",
        type: "uint256[]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "blocksCreated",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "benefactors",
        type: "address[]",
      },
      {
        name: "kind",
        type: "uint16[]",
      },
    ],
    name: "reward",
    outputs: [
      {
        name: "receiversNative",
        type: "address[]",
      },
      {
        name: "rewardsNative",
        type: "uint256[]",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        name: "receiver",
        type: "address",
      },
      {
        indexed: true,
        name: "bridge",
        type: "address",
      },
    ],
    name: "AddedReceiver",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        name: "cumulativeAmount",
        type: "uint256",
      },
      {
        indexed: true,
        name: "bridge",
        type: "address",
      },
    ],
    name: "BridgeNativeRewardAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "receivers",
        type: "address[]",
      },
      {
        indexed: false,
        name: "rewards",
        type: "uint256[]",
      },
    ],
    name: "MintedNative",
    type: "event",
  },
];
